/** @jsx jsx */
import { Container, jsx, Flex, Box, useColorMode, Styled } from "theme-ui"
import { Link } from "gatsby"
import useSiteMetadata from "../hooks/use-site-metadata"
import Logo from "../icons/logo"
import ThemeSwitch from "../icons/theme-switch"
import { GiBookshelf, GiToolbox } from "react-icons/gi"
import { FcDocument } from "react-icons/fc"
import { ImBlog } from "react-icons/im"

const modes = [`light`, `dark`, `strangerThings`]

const Header = () => {
  const [mode, setMode] = useColorMode()

  const cycleMode = (e: any) => {
    const i = (modes.indexOf(mode) + 1) % modes.length
    setMode(modes[i])
  }
  const site = useSiteMetadata()

  const renderIcon = (icon) => {
    const Icon = {
      GiBookshelf: GiBookshelf,
      ImBlog: ImBlog,
      GiToolbox: GiToolbox,
      FcDocument: FcDocument,
    }[icon]
    return <Icon />
  }

  return (
    <header>
      <Container sx={{ pb: 0 }}>
        <Flex
          sx={{
            alignItems: `center`,
            justifyContent: `space-between`,
            flexDirection: [`column`, `row`],
          }}
        >
          <Flex>
            <a
              aria-label="Link to the theme creator's website"
              sx={{ color: `text`, "&:hover": { color: `primary` } }}
              href="http://www.falloutcoder.com"
            >
              <Logo sx={{ width: 12, height: 12 }} />
            </a>
            <Box
              aria-hidden="true"
              focusable="false"
              sx={{
                height: 12,
                width: 1,
                backgroundColor: `primary`,
                borderRadius: `full`,
                mx: 3,
              }}
            />
            <Flex sx={{ flexDirection: `column` }}>
              <Box
                sx={{ fontSize: 3, fontWeight: `semibold`, lineHeight: `25px` }}
              >
                Falloutcoder
              </Box>
              <Box sx={{ color: `dark` }}>Developer</Box>
            </Flex>
            <button
              type="button"
              sx={{
                variant: `buttons.transparent`,
                p: 1,
                borderRadius: `full`,
                ml: 4,
              }}
              title="Change color mode"
              onClick={cycleMode}
            >
              <ThemeSwitch />
            </button>
          </Flex>
          <Flex sx={{ mt: [4, 0], alignItems: `center` }}>
            {/* <a href="https://github.com/falloutcoder" sx={{ variant: `buttons.secondary` }}>
              <svg height="32" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                ></path>
              </svg>
            </a> */}
            <nav>
              <Flex
                sx={{
                  mb: [3, 2],
                  flexWrap: `wrap`,
                  alignItems: [`flex-start`, `center`],
                  flexDirection: [`column`, `row`],
                }}
              >
                {site.menuLinks.map((link) => (
                  <Styled.a
                    key={link.name}
                    as={Link}
                    to={link.link}
                    sx={{
                      fontWeight: `semibold`,
                      mr: 3,
                      color: `text`,
                      "&:hover": { color: `primary` },
                      fontSize: [1, 2],
                    }}
                  >
                    {link.name} {renderIcon(link.icon)}
                  </Styled.a>
                ))}
              </Flex>
            </nav>
          </Flex>
        </Flex>
      </Container>
    </header>
  )
}

export default Header

import React from "react"

const defaultProps = {
  hidden: true,
}

type Props = { className?: string; hidden?: boolean } & typeof defaultProps

const Logo = ({ className, hidden }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    enableBackground="new 0 0 50 50"
    aria-hidden={hidden ? `true` : `false`}
    focusable={hidden ? `false` : `true`}
    aria-label={hidden ? `` : `Falloutcoder Logo`}
    fill="currentColor"
  >
    <image
      id="image0"
      width="50"
      height="50"
      x="0"
      y="0"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                CXBIWXMAAA7DAAAOwwHHb6hkAAAScElEQVR42u3deXCc5WHH8a+kPXRYsi7rNLYs35jD2BwOJoZQ
                CgRSxpSUNikdmmQmaVraKdNkSGBgOm5pJy0dpkfKpClDSUNb6EBmoDhDOUxaDicB29gWyPiQLduS
                LVuyDus+3D8eb3dXe+h9VtK+u/v+PjM7u/vqfXafd7W/fd/3ed/3efKAC4hIXPluV0AkkykgIkko
                ICJJKCAiSSggIkkoICJJKCAiSSggIkkoICJJ+NyuAMCiFXDT11IrW7sMFlS4V/fDu8KPd/0EDv/C
                vbo4tfxa2HB39LSqxVBeM7vXzS+A2ub0LktpJQQX2JVpfQ/+bIuzefOwONVk2Qa48nYo8MHKzeAv
                MtODRVBSHvFB+aC4LLqsLwCBovR+eG4ZH4XxEeg9BZ0HYefz8N6/z897rbnRfLnLqs0XpXY11DRB
                WZX5zEvKIS8f/IXgD7r9yWQGm4BYrUGuugPu+VO3Fy/z+YPmVrwQGlbD0ivmNiCrNsO9fw5N66Go
                1O2lzW1WAald7XZ1s8vkBBxvgecfSa18RSM0Xw3ldbBwEay7BaqXQHk9+PxuL503WAWkssHt6maX
                Y3th8Bzc8aC5ORXalq9abDaPxD1WAfEXul3d7NK8we0ayGxZ/T4FS9yurkh6WQUkPyMahUXSxyog
                ATUTisdYBSQvz+3qiqSXVUD8HjnQJxJitwZxu7YiaWa3BlEzr3iMXStWgdvVFUkvq4AU6PQG8Rid
                yCCeMzbsfF67TSztpUsOGJ+vgBQE3F40kfTSJpbkvAtT0c97Op2XVUAk550/F/18YD4CsiXFa8ZF
                3NZ3OvWyWoNIzjvRGv18atJ5WQVEcl7HfujrCj+fmHBeVgERT+g4EH48pYCIRDuwM/xYARGZ5u2n
                TS8zAGMjzss5DkjtCrcXUSR1Zw7BQLd5PD7qvJzWIOIZZ9vN/eiQ8zIKiHjGR6+ae5vjIgqI5Lzi
                SnPf0wmTY9C+z3lZBURyXuNac9+2G45/AkM9zssqIJLzKurN/bFd8L0v2JVVQCTnlUSMH9NvcaIi
                WARk4SK3F1MkNbMZIsJxQHzqVVGyVKHlCFSRtIklnrD10dTKKSDiCYvXpVZOARFPSHVwUQVEPKFq
                cWrlFBDxhLJqKE1hmGsFRDwhLx+arrIvp4CIZ5RV25dRQMQzFlTal1FAxDPKUxjG3HFAylPYwRHJ
                JMtSGJZbaxDxjFSaehUQ8YzIs3qdUkDEM4oX2pdRQMQz/EHYeK9dGQVEPGXtZrv5FRDxlKYr7OZX
                QMRTbFuyFBDxlFAHDk4pIOIp/kJo2uh8fgVEPKfU4qRFn9uVzUQ2fbfmkjwgUOx2LeafzfEQBSTC
                1ATsfAl6T7ldE/eU18GmL0J+Dm9bBC1+BHL4Y7DXfdLdcBSVwk33z66bmtnqPQV9Of4DUVjifF6t
                QSJMjLn7/sMD8Pazbn8KMGkxyGU28hc6n1cBSeDarXY7c9nu7DH46HW3a5EeCsgcCBTZbatmO5sv
                TbZbbHE0Xfsg4jnFFn31KiDiOUGLRhAFRDynUM28IonlW+x5KyDiOTbHQRQQ8ZwCv/N5Ha9sRgbd
                XizvGOiGA++Zf+T62yAvz+0a5RafRUAcr0EUkPTpPgFdbTA2pHDMB5tjPtrEykChc6EWqrM+1ykg
                Lpqaij/93MWRWCsbk88n88/xPoiXTwGfSyPnoeNTOHUIll0F9Suj/z5wFob6zeOyi2uQ04fhyIdQ
                v8rMP5tRW8VuQFrnO+kDbi9W9vv0fTj0y+TzHN1r7qsWRzdH9nWZW+s70LwRVm2C/AK3lyg72ezX
                OQ7I6Ta3Fyv7XLgA/Weg44C5ha5ULK+DuuXhTaiQiTHoPGAeN10Znl7ZCGs2w+kjZvPryIdwvAUa
                VplbeZ0ZIEbmnuOAvP+v8MAzblc3e4wOwb43oOtoeFpZNVx2s/lCxzNwFibGTTNk7fLw9GCxWWs0
                bzSbui1vm7XJsb3mVtFgmoO16TX3rE53nxgDX8DtKme2rjY42QqdB81zf6HZbwj90ifbLOrpMPfT
                1yyRyuvgM/ealq7OQ2Zf5lwH7HgGapqgcW3sfo1EszkOYhWQ0UEFZCYfvBJ+vGoTrLjWedm+LnNf
                uij5fPn5Zq1R0QCXboH2fbB/h1lbDZ9XQGZiszlqteXa323uJ8bhyC63FzMzVUSMYnTmmNlfGO6f
                udzUlFkTAFQ77P1v5LwJx6lD4WluXs+ei6zWIB2tUL/C7HAOnnO76pnpM180wdi93exQn+uE1nfN
                TveKa82VivH0doZ34stmWINMjJnWsKN7YCri+vGrPq+1x1yzCsjOF8w/+YVH4bYH3K565lq0FG75
                hjl+0XnQBOboR+aHpXqp+RIvWhJ92nVojVy/Kv6pEFNT5rrx0OuNDUOBD2qWmderW253Ep44YxWQ
                9/7N3EABmUl+vvni1q+EwV7Y/5Y5xyrU5Ovzw3X3mNNJxkfMzj3A0stjX6uvC37+otm0DSmthqtu
                T23kVnFOnTakQUk5XPfrpon2ZCucOmwaPIb6TED6Tpv5Ckui92FChvpMOIIlUNsMjavjzydzTwFJ
                o/I6c1uzGQ7+Itx7YfcJc1/REP8ob36+OQay4hq1IqabAuKCAr8JCRfMc1/A7EvULIs/f21z9IFD
                SR8FxE0X1xbLr3E2n6SfzuARSUIBEUlCARFJQgERSUIBEUlCARFJIuVm3ly/3LN9v7eGPxjsdbsG
                mSnlgFQvcbvq86t9n9s1kEyQUkDK683ZqNloYgwmJ+L/7cIFt2uXGSbHE4/06/N766zhlALStMGu
                h+xMcuiX5iImSezD/0r8t8s+B0sud/5a2S6lr3mVwyveJLn8Ali2IfmQy1NTcOQDrd3cklJASqvc
                rnbqCjJozTc1CRV1yS+THepTONyU0tflitvdrnbqGlaby2BHzrtdE6P13ZnnyZSLoorKYFGT27VI
                r5QCkui07GxQUm6GeBZxwvpA4arNM3cqIJIrrAPype9l1na8yHyyCkhxZfxOBUSyyeSk83mtArJs
                g+k4QCSbTU04n9cqINWXuL1oIullFZCScrerK5JeVgEpq7OZWyT7WQXkknVuV1ckvawCMlO3/CK5
                xq6Zd6Hb1RVJL6uAFHroCjsRsAyIjoGI11gFRB0nSzaazZnbdgHx0KWWkjuGB1Iv6/i0w7L69F5m
                e+A9+N8fwfhoeNq6W+CzX5rdmOCjg7DnNTNex3RjI7D97+Bz98PC2vQtazZo3w8dn8Cm33C7JvYm
                LU4tmc7xV239HelboNFB2LYFKhbDDfeFb1d/YXbhAHjtKdj1Svy/7X0d/vNh96/guzDl7vtPN9QH
                311vApKNIn9kbTleJwSLnM45e6FV4tIr4fJfSd/7ZoLBc/Dhdtjy227XJHfkAZNjUJDCPrTj3+Py
                Rqdzymy8+TS0vOF2LXJLsATOnkitrOM1iBsXSU2Mm32RkAUV0Lg2ep4zx6DnZPS0QCEsXgf+4NzW
                J957ASy5zFyvPV3nQdO/VNOV0dNPfgJ5BdCwKnb+rjYzaGfkcq++Pnq+8VEz1uHoYPT0htXRHWpM
                TULHp7FDdheWwOJL4/dvNT4KJ1rM/ljITNvww/1mH2W6ykYz4u907fuhrNp8Nr2nzP+pcc38HUYI
                FJmOAGubzXObTVjHX/tyF05UfOufoeW/w8//ck/037va4MEE44L/7j/Cr3597uoyOgiP3QD9nbF/
                u/UP4P6/jZ3+0ydhbAJ+75+ip7/8V9C4Du76Vnja6SOw7ebw6+97Lfy356Z9Qd9/AX7wlehpdz4E
                K6+Lntb6Ljx+c/zl+cPnYdM9sdPffhb+5fejp3371eSfzX88Am88FTu9rB6+3xbbuPPSNtPgsv1v
                oOuwmfbZr8Z+TnMlUASf7oQrbzXPJ8acl83oi2dv/SY8vD3x32uWxX55AJ5/xPyT5zIgbXtgyTr4
                7vHo6fvehDd/MPvXr22Gp47Dy0/AyRb45jOJ5z2009kXat/FH5fpn9GP/wSe/ePYgEyMm8/trofg
                Nx8PTx/qS/4+pw/Dd16L3V98+Bo48Un8jubqlsOTB8zjl7bBi9vg/ifir4lnyx+AAz83aw7bRh7H
                AcnU00xGB01viVMRl1HOR5c+4yOzf40547SVLcl88daEqbaeJSvn5ODyDb8DKzeDb443if9fHixe
                YYJeUmFX1HlAFjidM32O7oFHrobrv2x6KczPh88/mJl19YKdL8KOH4af3/aA2S+aSbIRfudKw6Uw
                0DOPAclEu14291//YfQO+ftuV8yjNt0Tf78mE1QvgZ4TZtNuXnbSM1HlxR7mP30/elXee2ru36u0
                yoyhEdm6BHC8JXFv574gdJ2ILTPQnfh9FtbAxzuSt2Ilet2lV0SvPRdebFiZPl9PB6y6Ifa9C3ym
                8aB72muPJejpPSRQbD6HQJxjZYvX2v9qz4eyKrNMl94I47mykz6T9bfDulvhL24JT7vzofnpqrNp
                vTn9ZNuW2L/90Qvxy9z0NXMEel9EQ8N9T8KC8sTvc81WeO3vo99n+k52vNcFePJgdEA23gU7fhRb
                55rl8J2fxr53fgF842l44m5497nw9K/EaaGKdP2X4R9+K/7fHv8gMwJSXgdn2szjyXHn5fJwuMv3
                8Buw7qb0LMzUpNnRDhTNvJMXmjekqNQ0442Pxr/AK3TsIF6b+8QYjA2bL1m8EbQmJ2KPPeTlm/dM
                ZHw0ege/qNS8R6I6RNYjJN5yTH9diF/veGN9+IPgL0xc57Hh6KbQYIlZbn9h4mNLwwPxN12CxbFr
                2JHzZtpcH6dKZvAcPPVV+NZPoOMAfNvh5eOO1yDp/BXIL3B+9WK8ef2Fib8AyQ5G+QLJA1ngs7+q
                0h+M/SLMdEBspnoket24dfbb1zlQFLu5NNNrJPuRmM6NRpSSCtj9ivlRsWnlzOpNLBEbTRth/w44
                d9J5GQVEPKOkAp74NVh/p/MyGgZaPCPUeLPnVedlFBDxjFQaBRQQ8YxUGgcUEPEM/3xeMCWS7fwp
                XBWrgIhnaA0iksSS9fZlFBDxjAXV9mUcB2S23e2IuC2Vi/4cf+1L1LO7ZLlURifQekE8I5W+pRUQ
                8YzgfG5iiWS7ee1ZUSTbBXQulkhi+T5osByIVgERT1mxyW5+BUQ8ZVGT3fwKiOS8yM4kKhvsyiog
                kvNGI3qIse18RAGRnBfZVVOh5RALjgNSVO72YoqkJrKPsVLLExa1BpGcFznK7QJtYolE6z8Tfmx7
                PpYCIjmvJ2IsFL/l+VgKiOS8s4fCm1lFCohIrNBmVr7PjJ3olAIintAdMQx0hQIiEq07osPqoEX3
                PwqIeEL73vBjm/6xFBDxhI/eDD+2GWpaARFPOLkr+oi6UwqIeEaoqTdQ6LyMAiKeMdxv7uONP5mI
                AiKekWz47UQUEPGMng5zH1Arlkis9t3mPi/PeRkFRDxj2GL45xDHAQmk0OmWSCYJ7aTPSyuW3+JF
                RTJR/1n7MtrEEs8412FfRgERzzi2K7oDBycUEPGU3tN28ysg4ildbXbzKyDiKe0tdvMrIOIpbz8d
                3dPiTBQQ8ZSOFhjqcz6/L90VnByD/m7T5Na2C/o6E89b0Qj1a6G8BkqrIFAM/hQGQfGyC1Mw2AdD
                vVDgN604vgCUlJsODIpK01+nkfMwOQ6DvTDUb+rW+rP5e7+Pp7126/84L5sHXHAy43MTc1/xqQlz
                AtnRPbD/TXj9+zOXWXsbXH0nNK6GqkugqgGCC+a+brlseADOHIOTn5gfqdafwYa7ofoSWLgIFtaY
                EWFLqyBo2ZftbAz1mduZY3D6CJxshe1/7e5n5WpAphsfhb7T0HEQPn4Lek7AvregvzN5uUXNsORy
                8w8tKoX6NdC4DqoazS9l4QK7awC8ZmLcnIbRdxrOtsOBd8yp4V1t0PIGNF9nPtfSKrP2KS6DAl/4
                c131WfAFzZo+v8AMlukvNGso286i4xkbhtEhGDwHvV1w6gB0HzNrxsFeGOiBM0fN5tNcy6iATDc1
                AW0fwRNbZw7JTLY+BvWroKYJapvNr6TMbLAfdm+Hp+6b3etsfSzcWcLyDeY+tKYCKK2c/ZbA6KAJ
                dudBOLwL3v3x7EPjOCCPWmy3JXO8BQamfdnHR2F8xDwe7DPbp72n4WQL9M4yGMmsudH8Ci6oNL94
                hcXmWgFfAFZuDv9Dy6rCfboWXfz1jFRcdvGTzDKTkzCa4AzX0SHzPxkZhKO74Z3nzNokHRY1Q01z
                +HlZtdl/ihQsnvmkw8lJs5nWvhcGulKri+OAiHiRmnlFklBARJJQQESSUEBEklBARJJQQESSUEBE
                klBARJJQQESS+D9VtVaUIv6TtQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wNi0xNVQwMzoyODo0
                MCswMzowMAwxT1sAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDYtMTVUMDM6Mjg6NDArMDM6MDB9
                bPfnAAAAAElFTkSuQmCC"
    />
  </svg>
)

export default Logo

Logo.defaultProps = defaultProps
